import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { StoryBlokService } from '../service/storyblok.service';
import { ThemeService } from '../service/theme.service';

@Injectable({ providedIn: 'root' })
export class StoryblokArticleResolver implements Resolve<any> {
  constructor(
    private storyBlokService: StoryBlokService,
    private router: Router,
    private themeService: ThemeService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const urlSegments = route.url.map((segment) => segment.path).join('/');

    let storyPath = urlSegments || '';
    return await this.storyBlokService
      .getStory(storyPath)
      .catch(async (err) => {
        return await this.storyBlokService
          .getStories({
            by_slugs: '*' + route.url.map((segment) => segment.path)[2],
          })
          .then((data) => {
            return {
              story: data.stories[0],
            };
          });
      })
      .then((result) => {
        this.themeService.viewPage(result);
        const title =
          (result?.story?.content as any)?.metatags?.title || result.story.name;
        const description = (result?.story?.content as any)?.metatags
          ?.description;
        return {
          ...result,
          seo: { title, description },
        };
      })
      .catch(async (err) => {
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: false,
        });
      });
  }
}
