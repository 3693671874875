import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DynamicModule } from 'ng-dynamic-component';
import { StoryblokDirective } from 'src/app/directive/storyblok.directive';
import { EnvironmentService } from 'src/app/service/environment.service';

@Component({
  selector: 'app-ribbon',
  standalone: true,
  imports: [NgIf, DynamicModule, NgFor, StoryblokDirective],
  animations: [
    trigger('fade', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition(':enter, :leave', [animate('.3s')]),
    ]),
  ],
  templateUrl: './ribbon.component.html',
  styleUrl: './ribbon.component.scss',
})
export class RibbonComponent implements OnInit {
  @Input() theme!: any;

  @Input() _editable!: string;
  @Input() _uid!: string;
  @Input() component!: string;
  currentMessage: any;
  currentMessageIndex: number = 0;
  messageInterval: number = 5000;  

  components: any;

  _started: boolean = false;

  constructor(private environmentService: EnvironmentService) {
    import('src/app/storyblok/components').then((cp) => {
      this.components = cp.Components;
    });
  }

  ngOnInit(): void {
    if (this.theme?.content?.ribbon.length > 0) {
      this.currentMessage = this.theme?.content?.ribbon[0];
      if (this.environmentService.isBrowser()) {
        this.startAnimation();
      }
    }
  }

  startAnimation() {
    if (this._started) {
      return;
    }
    this._started = true;
    if (this.theme?.content?.ribbon.length > 1) {
      setInterval(() => {
        this.currentMessageIndex =
          (this.currentMessageIndex + 1) % this.theme?.content?.ribbon.length;
        this.currentMessage =
          this.theme?.content?.ribbon[this.currentMessageIndex];
      }, this.messageInterval);
    }
  }
}
