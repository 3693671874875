import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { StoryBlokService } from '../service/storyblok.service';
import { ThemeService } from '../service/theme.service';

@Injectable({ providedIn: 'root' })
export class StoryblokAllBlogsResolver implements Resolve<any> {
  constructor(
    private storyBlokService: StoryBlokService,
    private router: Router,
    private themeService: ThemeService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const urlSegments = route.url.map((segment) => segment.path).join('/');

    const storyPath = urlSegments || 'home';

    const articles = await this.storyBlokService.getStories({
      content_type: 'article',
      sort_by: 'published_at:desc',
    });

    return {
      ...(await this.storyBlokService
        .getStory(storyPath, {
          resolve_relations: ['all-blogs.blogs'],
        })
        .then((result) => {
          this.themeService.viewPage(result);
          const title =
            (result?.story?.content as any)?.metatags?.title ||
            result.story.name ||
            'All Blogs';
          const description = (result?.story?.content as any)?.metatags
            ?.description;
          return {
            ...result,
            seo: { title, description },
          };
        })
        .catch((err) => {
          this.router.navigate([{ outlets: { error: ['404'] } }], {
            skipLocationChange: false,
          });
        })),
      articles,
    };
  }
}
