<header
  preventDefault
  *ngIf="theme?.content"
  [ngClass]="{ active: _active, 'search-open': _activeSearch }"
  [style.marginTop]="_isScrolled || !showribbon ? '0px' : '32px'"
  class="d-flex container flex-wrap align-items-center justify-content-center justify-content-lg-between py-4"
>
  <div class="col-6 col-lg-3">
    <a [routerLink]="['/']" class="d-flex logo w-100 h-auto">
      <img
        priority
        class="action w-100 h-auto"
        width="160"
        height="33"
        placeholder
        [ngSrc]="theme?.content?.logo?.filename"
      />
    </a>
  </div>

  <ul
    class="nav d-none d-lg-inline-flex col-lg-auto mb-2 justify-content-center mb-lg-0"
  >
    <ng-container *ngFor="let blok of theme?.content?.desktop_menu">
      <li
        *ngIf="blok?.menus?.length > 0 || blok?.links?.length > 0"
        class="mx-3 action"
        (click)="_active = blok; closeSearch()"
      >
        {{ blok.label }}
      </li>
      <li *ngIf="!blok.menus && !blok.links" class="mx-3 action">
        <a [storyblokLink]="blok.link">{{ blok.label }}</a>
      </li>
    </ng-container>
  </ul>

  <div
    class="col-6 col-lg-3 d-flex justify-content-end align-items-center icons"
  >
    @defer {
    <a
      href="https://shopify.com/9607794/account
"
    >
      <svg
        class="d-none d-lg-inline action account"
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 9.36914C12.2091 9.36914 14 7.57829 14 5.36915C14 3.16001 12.2091 1.36914 10 1.36914C7.79087 1.36914 6 3.16001 6 5.36915C6 7.57829 7.79087 9.36914 10 9.36914Z"
          stroke="black"
          stroke-width="2"
        />
        <path
          d="M19 21.3691C19 16.3986 14.9706 12.3691 10 12.3691C5.02945 12.3691 1 16.3986 1 21.3691"
          stroke="black"
          stroke-width="2"
        />
      </svg>
    </a>

    <svg
      class="d-none d-lg-inline action search"
      (click)="toggleSearch()"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2122 17.7935C14.1954 17.7935 17.4244 14.5645 17.4244 10.5814C17.4244 6.59816 14.1954 3.36914 10.2122 3.36914C6.22902 3.36914 3 6.59816 3 10.5814C3 14.5645 6.22902 17.7935 10.2122 17.7935Z"
        stroke="black"
        stroke-width="2"
      />
      <path
        d="M15.8789 16.248L20.0002 20.3693"
        stroke="black"
        stroke-width="2"
        stroke-linecap="square"
      />
    </svg>
    <a [routerLink]="['/wishlist']">
      <svg
        class="d-none d-lg-inline action wishlist"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 4.36914H6V19.3691L12 15.4079L18 19.3691V4.36914Z"
          [attr.stroke]="wishlist?.length > 0 ? '#DE178F' : 'black'"
          stroke-width="2"
          [attr.fill]="wishlist?.length > 0 ? '#DE178F' : 'transparent'"
          stroke-linecap="square"
        />
      </svg>
    </a>
    <svg
      (click)="toggleMinicart()"
      class="action cart"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2847_7508"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path
          d="M23.5647 0.931641H0.742432V23.7539H23.5647V0.931641Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2847_7508)">
        <path
          d="M8.44467 21.36C8.91802 21.36 9.30185 20.9763 9.30185 20.5029C9.30185 20.0295 8.91802 19.6458 8.44467 19.6458C7.97128 19.6458 7.58752 20.0295 7.58752 20.5029C7.58752 20.9763 7.97128 21.36 8.44467 21.36Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.4875 21.36C18.9609 21.36 19.3447 20.9763 19.3447 20.5029C19.3447 20.0295 18.9609 19.6458 18.4875 19.6458C18.0141 19.6458 17.6304 20.0295 17.6304 20.5029C17.6304 20.9763 18.0141 21.36 18.4875 21.36Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.19824 2.30103H4.8498L7.29634 14.5246C7.37982 14.9449 7.60847 15.3225 7.94226 15.5912C8.27604 15.8598 8.69374 16.0026 9.12214 15.9944H17.9955C18.4239 16.0026 18.8415 15.8598 19.1753 15.5912C19.5091 15.3225 19.7378 14.9449 19.8212 14.5246L21.2818 6.86547H5.76269"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle *ngIf="cart?.length > 0" cx="19" cy="6" r="4" fill="#DE178F" />
      </g>
    </svg>
    <svg
      (click)="toggleMobileMenu()"
      class="d-lg-none action"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.9"
        d="M4.22183 12.6312L19.7782 12.6312"
        stroke="black"
        stroke-width="2"
        stroke-linecap="square"
      />
      <path
        opacity="0.9"
        d="M4.22189 8.63149L19.7782 8.63149"
        stroke="black"
        stroke-width="2"
        stroke-linecap="square"
      />
      <path
        opacity="0.9"
        d="M4.22189 16.6317L19.7782 16.6317"
        stroke="black"
        stroke-width="2"
        stroke-linecap="square"
      />
    </svg>
    } @placeholder {
    <div></div>
    }
  </div>
</header>
<div preventDefault class="desktop-menu" [@fadeInOut] *ngIf="_active">
  <ng-container *ngFor="let blok of theme?.content?.desktop_menu">
    <ndc-dynamic
      *ngIf="components && blok == _active"
      [ndcDynamicComponent]="components[blok.component]"
      [ndcDynamicInputs]="blok"
    >
    </ndc-dynamic>
  </ng-container>
</div>
<div preventDefault class="mobile-menu" [@fadeInOut] *ngIf="_activeMobile">
  <ng-container *ngFor="let blok of theme?.content?.mobile_menu">
    <ndc-dynamic
      [ndcDynamicComponent]="components[blok.component]"
      [ndcDynamicInputs]="blok"
    >
    </ndc-dynamic>
  </ng-container>
</div>
@defer {
<div preventDefault class="search-overlay" [@fadeInOut] *ngIf="_activeSearch">
  <app-search-overlay [@fadeInOut]></app-search-overlay>
</div>
} @placeholder {
<div></div>
}
