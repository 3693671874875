import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionResolver } from './resolver/collection.resolver';
import { ProductResolver } from './resolver/product.resolver';
import { StoryblokAllBlogsResolver } from './resolver/storyblok-allblogs.resolver';
import { StoryblokArticleResolver } from './resolver/storyblok-article.resolver';
import { StoryblokBlogParentResolver } from './resolver/storyblok-blogparent.resolver';
import { StoryblokResolver } from './resolver/storyblok.resolver';

export const routes: Routes = [
  {
    path: 'cart',
    redirectTo: '/?modal=cart',
  },
  {
    path: 'collections/:handle',
    loadComponent: () =>
      import(
        './content-source/mercury-collection/mercury-collection.component'
      ).then((m) => m.ContentSourceMercuryCollectionComponent),
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      collection: CollectionResolver,
    },
  },
  {
    path: 'products/:handle',
    loadComponent: () =>
      import('./content-source/shopify-product/shopify-product.component').then(
        (m) => m.ContentSourceShopifyProductComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { product: ProductResolver },
  },
  {
    path: 'blogs',
    loadComponent: () =>
      import('./blog/all-blogs/all-blogs.component').then(
        (m) => m.AllBlogsComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { allBlogs: StoryblokAllBlogsResolver },
  },
  {
    path: 'ndis-checkout',
    loadComponent: () =>
      import('./content-source/ndis-checkout/ndis-checkout.component').then(
        (m) => m.NdisCheckoutComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: 'blogs/:slug',
    loadComponent: () =>
      import('./blog/blog/blog.component').then((m) => m.BlogComponent),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { blogParent: StoryblokBlogParentResolver },
  },
  {
    path: 'blogs/:handle/:slug',
    loadComponent: () =>
      import('./blog/article/article.component').then(
        (m) => m.ArticleComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { article: StoryblokArticleResolver },
  },
  {
    path: 'build-your-bundle',
    loadComponent: () =>
      import('./content-source/bundle-builder/bundle-builder.component').then(
        (m) => m.BundleBuilderComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: 'personaliser',
    loadComponent: () =>
      import(
        './content-source/personaliser/select-product-stage/select-product-stage.component'
      ).then((m) => m.SelectProductStageComponent),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: 'personaliser/:handle',
    loadComponent: () =>
      import(
        './content-source/personaliser/select-variant-stage/select-variant-stage.component'
      ).then((m) => m.SelectVariantStageComponent),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { product: ProductResolver },
  },
  {
    path: 'wishlist',
    loadComponent: () =>
      import('./content-source/wishlist/wishlist.component').then(
        (m) => m.ContentSourceWishlist
      ),
  },
  {
    path: ':slug',
    loadComponent: () =>
      import('./content-source/storyblok/storyblok.component').then(
        (m) => m.ContentSourceStoryblokComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: '',
    loadComponent: () =>
      import('./content-source/storyblok/storyblok.component').then(
        (m) => m.ContentSourceStoryblokComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: '**',
    loadComponent: () =>
      import('./content-source/storyblok/storyblok.component').then(
        (m) => m.ContentSourceStoryblokComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: '404',
    loadComponent: () =>
      import('./components/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
    outlet: 'error',
  },
  {
    path: 'styleguide',
    loadComponent: () =>
      import('./components/styleguide/styleguide.component').then(
        (m) => m.StyleguideComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
