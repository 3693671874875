import {
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { DynamicModule } from 'ng-dynamic-component';
import { StoryblokLinkDirective } from 'src/app/directive/storyblok-link.directive';
import { StoryblokDirective } from 'src/app/directive/storyblok.directive';
import { EmailFormComponent } from './email-form/email-form.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    DatePipe,
    StoryblokDirective,
    RouterModule,
    DynamicModule,
    NgOptimizedImage,
    StoryblokDirective,

    RouterModule,
    StoryblokLinkDirective,
    EmailFormComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  date: Date = new Date();
  @Input() _editable!: string;
  @Input() _uid!: string;
  @Input() component!: string;
  @Input() theme: any;

  components: any;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    import('src/app/storyblok/components').then((cp) => {
      this.components = cp.Components;
    });
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        if (value.url === '/blogs') {
          this.renderer.setStyle(
            this.elRef.nativeElement,
            'background-color',
            '#102772'
          );
          this.renderer.addClass(this.elRef.nativeElement, 'text-white');
        } else {
          this.renderer.setStyle(
            this.elRef.nativeElement,
            'background-color',
            'var(--bs-yellow)'
          );
          this.renderer.removeClass(this.elRef.nativeElement, 'text-white');
        }
      }
    });
  }
}
