import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  afterNextRender,
  afterRender,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, map } from 'rxjs';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MinicartComponent } from './components/minicart/minicart.component';
import { RibbonComponent } from './components/ribbon/ribbon.component';
import { StoryblokDirective } from './directive/storyblok.directive';
import { AnalyticsService } from './service/analytics.service';
import { SEOService } from './service/seo.service';
import { ShopifyService } from './service/shopify.service';
import { StoryBlokService } from './service/storyblok.service';
declare global {
  interface Window {
    storyblok: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RibbonComponent,
    StoryblokDirective,
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
    MinicartComponent,
  ],
})
export class AppComponent implements OnInit {
  story: { content: any; name: string } | any = { content: null, name: '' };
  _showribbon: boolean = false;
  @Input() _editable!: string;
  components: any;
  _notFound: boolean = false;
  constructor(
    private storyBlokService: StoryBlokService,
    private seoService: SEOService,
    private analyticsService: AnalyticsService,
    private shopifyService: ShopifyService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.seoService.init();
    this.analyticsService.init();
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationError),
        map((evt) => evt as NavigationError)
      )
      .subscribe((evt) => {
        if (evt.error instanceof Error && evt.error.name == 'ChunkLoadError') {
          window.location.assign(evt.url);
        }
      });
    this.router.events.subscribe((value: any) => {
      if (value?.url) {
        this._notFound = value.url.includes('(error:404)');
      }
    });
    afterRender(() => {
      this.router.events.subscribe((value: any) => {
        if (value instanceof NavigationEnd) {
          const url = structuredClone(value.url.split('?')[0]);

          this._showribbon =
            url === '/' ||
            ['products', 'collections'].includes(url.split('/')[1]);
        }
      });
    });
    afterNextRender(() => {
      try {
        this.shopifyService.createCart().subscribe((data) => {});
        if (window?.storyblok) {
          window.storyblok.init();
          window.storyblok.on(['published'], function () {
            location.reload();
          });
          window.storyblok.on('input', (event: any) => {
            if (
              this.story &&
              event.story.content._uid === this.story?.content?._uid
            ) {
              this.story = event.story;
              this.cd.markForCheck();
            }
          });
          window.storyblok.on('enterEditmode', (payload: any) => {});
        }
      } catch (err) {
        console.log(err);
      }
    });
    import('src/app/storyblok/components').then((cp) => {
      this.components = cp.Components;
    });
  }

  async ngOnInit() {
    (await this.storyBlokService.getTheme()).subscribe((theme) => {
      this.story = theme;
    });
  }
}
