import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Collection, Product } from '../classes/utility';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private environmentService: EnvironmentService
  ) {}

  generateWebsiteJsonLd(
    url: string,
    name: string,
    searchActionUrl: string
  ): any {
    try {
      if (this.environmentService.isBrowser()) return;
      const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: url,
        name: name,
        potentialAction: {
          '@type': 'SearchAction',
          target: `${searchActionUrl}?q={search_term_string}`,
          'query-input': 'required name=search_term_string',
        },
      };
      this.insertJsonLdIntoDom(jsonLd, 'website');
    } catch (err) {
      console.log(err);
    }
  }

  generateCollectionJsonLd(collection: Collection) {
    try {
      if (this.environmentService.isBrowser()) return;
      const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: environment.siteName,
            item: environment.siteUrl,
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: collection.title,
            item: environment.siteUrl + '/collections/' + collection.handle,
          },
        ],
      };

      this.insertJsonLdIntoDom(jsonLd, 'collection');
    } catch (err) {
      console.log(err);
    }
  }

  generateProductJsonLd(product: Product): any {
    const regex = /<\/?[^>]+(>|$)/g;
    let description = product.body_html.replace(regex, '');
    try {
      if (this.environmentService.isBrowser()) return;
      let reviews;
      try {
        reviews = JSON.parse(product.metafields.okendo.summaryData);
      } catch (err) {}
      const jsonLd = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: product.title,
        image: product.images.map((image: any) => image.src), // Assuming ProductImage has a 'src' property
        description,
        sku: product.variants[0]?.sku,
        mpn: product.variants[0]?.barcode,
        brand: {
          '@type': 'Brand',
          name: product.vendor,
        },
        ...(reviews &&
          reviews.reviewAverageValue > 3 && {
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '' + reviews.reviewAverageValue || 5,
              reviewCount: '' + reviews.reviewCount || 1,
            },
          }),
        offers: product.variants.map((variant: any) => ({
          '@type': 'Offer',
          hasMerchantReturnPolicy: {
            '@type': 'MerchantReturnPolicy',
            applicableCountry: 'AU',
            returnPolicyCategory:
              'https://schema.org/MerchantReturnFiniteReturnWindow',
            merchantReturnDays: 30,
            returnMethod: 'https://schema.org/ReturnByMail',
            returnFees: 'https://schema.org/FreeReturn',
          },
          url: `${environment.siteUrl}/products/${product.handle}`,
          priceCurrency: 'AUD',
          priceValidUntil: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .split('T')[0],
          price: variant.price,
          itemCondition: 'https://schema.org/NewCondition',
          availability:
            variant.inventory_quantity > 0 ||
            variant.inventory_management == 'ALLOW'
              ? 'https://schema.org/InStock'
              : 'https://schema.org/OutOfStock',
          seller: {
            '@type': 'Organization',
            name: product.vendor,
          },
        })),
      };
      this.insertJsonLdIntoDom(jsonLd, 'product');
    } catch (err) {
      console.log(err);
    }
  }

  insertJsonLdIntoDom(
    jsonLd: any,
    type: 'product' | 'website' | 'collection'
  ): void {
    let script = this.document.querySelector(
      'script[type="application/ld+json"][data-type="' + type + '"]'
    ) as HTMLScriptElement;
    if (script) {
      script.textContent = JSON.stringify(jsonLd);
    } else {
      script = this.document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(jsonLd);
      this.document.head.appendChild(script);
    }
  }
}
