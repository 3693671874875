import { NgClass, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationSkipped,
  Router,
  RouterModule,
} from '@angular/router';
import { DynamicModule } from 'ng-dynamic-component';
import { FadeInOut } from 'src/app/animations/fade';
import { Cart } from 'src/app/classes/utility';
import { StoryblokLinkDirective } from 'src/app/directive/storyblok-link.directive';
import { StoryblokDirective } from 'src/app/directive/storyblok.directive';
import { EnvironmentService } from 'src/app/service/environment.service';
import { ShopifyService } from 'src/app/service/shopify.service';
import { ThemeService } from 'src/app/service/theme.service';
import { WishlistService } from 'src/app/service/wishlist.service';
import { SearchOverlayComponent } from './search-overlay/search-overlay.component';

@Component({
  selector: 'app-header',
  standalone: true,
  animations: [FadeInOut(500, 500)],
  imports: [
    NgIf,
    NgFor,
    NgClass,
    DynamicModule,
    NgOptimizedImage,
    StoryblokDirective,
    RouterModule,
    StoryblokLinkDirective,
    SearchOverlayComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnChanges {
  @Input() theme: any;
  @Input() showribbon: boolean = false;
  @Input() _editable!: string;
  @Input() _uid!: string;
  @Input() component!: string;

  components: any;

  _active: any = null;
  _activeMobile: boolean = false;
  _activeSearch: boolean = false;
  _isScrolled: boolean = false;
  private previousUrl: string | undefined;

  cart: any;
  wishlist: any;
  window;

  constructor(
    private eRef: ElementRef,
    private environmentService: EnvironmentService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private router: Router,
    private shopifyService: ShopifyService,
    private route: ActivatedRoute,
    private wishlistService: WishlistService
  ) {
    this.window = this.environmentService.getWindow();

    import('src/app/storyblok/components').then((cp) => {
      this.components = cp.Components;
    });

    this.shopifyService.cartObservable.subscribe((data) => {
      if ((data as Cart)?.lines !== undefined) {
        this.cart = (data as Cart)?.lines?.edges || [];
      }
    });

    this.wishlistService.wishlistObservable.subscribe((data: any) => {
      this.wishlist = data.events;
    });

    this.themeService.themeEvents.subscribe((data) => {
      console.log(data);
      if (['clear', 'menuClose'].find((x) => x == data.type)) {
        this.close();
      } else if (data.type == 'searchOpen') {
        this.close();
        this._activeSearch = true;
        this.overflowHidden();
      } else if (['drawerOpen', 'minicartOpen'].find((x) => x == data.type)) {
        this.close();
        this.overflowHidden();
      } else if (['menuOpen'].find((x) => x == data.type)) {
        this.close();
        this._activeMobile = true;
        this.overflowHidden();
      }
    });

    this.router.events.subscribe((value: any) => {
      if (value instanceof NavigationSkipped) {
        this.close();
      }
      if (value instanceof NavigationEnd) {
        const currentUrl = value.url.split('?')[0];
        this.close();
        if (!this.previousUrl || this.previousUrl !== currentUrl) {
          setTimeout(() => {
            this.onWindowScroll();
          }, 60);
        }
        this.previousUrl = currentUrl;
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params.modal) {
        setTimeout(() => {
          if (params.modal === 'search') {
            this.themeService.searchOpen();
          }
          if (params.modal === 'cart') {
            this.themeService.minicartOpen();
          }
        }, 100);
      }
    });
  }

  overflowHidden() {
    setTimeout(() => {
      this.renderer.setStyle(
        this.environmentService.getWindow()?.document.body,
        'overflow',
        'hidden'
      );
    }, 50);
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.onWindowScroll();
    }, 60);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.close();
    }
  }

  toggleMobileMenu() {
    this.themeService.menuOpen();
  }

  toggleSearch() {
    this.themeService.searchOpen();
  }

  toggleMinicart() {
    this.themeService.minicartOpen();
  }

  close() {
    this.renderer.removeStyle(
      this.environmentService.getWindow()?.document.body,
      'overflow'
    );
    this._active = null;
    this._activeMobile = false;
    this._activeSearch = false;
  }

  closeSearch() {
    this.renderer.removeStyle(
      this.environmentService.getWindow()?.document.body,
      'overflow'
    );
    this._activeSearch = false;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.environmentService.isBrowser()) {
      const scrollTop =
        this.window?.pageYOffset ||
        this.window?.document.documentElement.scrollTop ||
        this.window?.document.body.scrollTop ||
        0;
      this._isScrolled = scrollTop > 0;
    }
  }
}
