import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { StoryBlokService } from '../service/storyblok.service';
import { ThemeService } from '../service/theme.service';

@Injectable({ providedIn: 'root' })
export class StoryblokResolver implements Resolve<any> {
  constructor(
    private storyBlokService: StoryBlokService,
    private router: Router,
    private themeService: ThemeService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const urlSegments = route.url.map((segment) => segment.path).join('/');

    let storyPath = urlSegments || 'home';
    if (storyPath == 'theme-settings') {
      storyPath = 'home';
    }
    return await this.storyBlokService
      .getStory(storyPath, { resolve_relations: ['info-sheet.menu'] })
      .then((result) => {
        this.themeService.viewPage(result);
        const title =
          (result?.story?.content as any)?.metatags?.title || result.story.name;
        const description = (result?.story?.content as any)?.metatags
          ?.description;
        return {
          ...result,
          seo: { title, description },
        };
      })
      .catch((err) => {
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: false,
        });
      });
  }
}
