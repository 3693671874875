import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { Collection } from '../classes/collection';
import { JsonLdService } from '../service/jsonld.service';
import { MercuryService } from '../service/mercury.service';
import { ThemeService } from '../service/theme.service';

@Injectable({ providedIn: 'root' })
export class CollectionResolver implements Resolve<any> {
  _url: string = '';
  constructor(
    private mercuryService: MercuryService,
    private themeService: ThemeService,
    private jsonLdService: JsonLdService,
    private router: Router
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const handle = route.paramMap.get('handle') as string;

    const queryParams = route.queryParams;
    return await this.mercuryService
      .getFilteredCollection(
        handle,
        this.mercuryService.calculateFilters(queryParams),
        queryParams?.sort || '',
        20,
        queryParams?.page ? (queryParams?.page - 1) * 20 : 0
      )
      .toPromise()
      .then((result: Collection | any) => {
        if (result) {
          this._url = handle;
          this.themeService.viewCollection(result);
          this.jsonLdService.generateCollectionJsonLd(result);

          return {
            ...result,
            seo: {
              title: result?.metafields?.global?.title_tag || result?.title,
              description:
                result?.metafields?.global?.description_tag ||
                result?.body_html,
            },
          };
        }
      })
      .catch((err) => {
        this.router.navigate([{ outlets: { error: ['404'] } }], {
          skipLocationChange: false,
        });
        return EMPTY;
      });
  }
}
