import { Injectable } from '@angular/core';
import {
  AddToCartItem,
  Cart,
  Collection,
  Product,
  ProductVariant,
} from '../classes/utility';
import { FbpService } from './fbp.service';
import { GtmService } from './gtm.service';
import { KlaviyoService } from './klaviyo.service';
import { ShopifyAnalyticsService } from './shopify-analytics-helpers/shopify-analytics.service';
import { ThemeService } from './theme.service';

declare global {
  interface Window {
    TriplePixel: any;
  }
}
type Action =
  | 'viewPage'
  | 'viewProduct'
  | 'addToCart'
  | 'viewCollection'
  | 'startNDISCheckout'
  | 'viewCart'
  | 'startCheckout'
  | 'addToWishlist';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private themeService: ThemeService,
    private gtmService: GtmService,
    private fbpService: FbpService,
    private klaviyoService: KlaviyoService,
    private shopifyAnalyticsService: ShopifyAnalyticsService
  ) {
    this.themeService.themeEvents.subscribe((data) => {
      if (data.action && this.isActionValid(data.action)) {
        try {
          (this[data.action] as Function).call(this, data.payload);
        } catch (err) {
          console.log(err);
        }
      }
    });
  }

  private isActionValid(action: string): action is Action {
    return [
      'viewPage',
      'viewProduct',
      'addToCart',
      'viewCollection',
      'viewCart',
      'startCheckout',
      'startNDISCheckout',
      'addToWishlist',
    ].includes(action);
  }

  init() {}

  viewPage(payload: { page: any }) {
    const { page } = payload;
    // this.klaviyoService.viewPage(page);
    // this.gtmService.viewPage(page);
    this.fbpService.viewPage(page);
    this.shopifyAnalyticsService.viewPage(page);
  }

  viewProduct(payload: { product: Product }) {
    const { product } = payload;
    this.klaviyoService.viewProduct(product);
    this.gtmService.viewProduct(product);
    this.fbpService.viewProduct(product);
    this.shopifyAnalyticsService.viewPage(product);
  }

  viewCollection(payload: { collection: Collection }) {
    const { collection } = payload;
    // this.klaviyoService.viewCollection(collection);
    this.gtmService.viewCollection(collection);
    this.fbpService.viewCollection(collection);
    this.shopifyAnalyticsService.viewPage(collection);
  }

  addToCart(payload: { addToCartItems: AddToCartItem[]; cart: Cart }) {
    const { addToCartItems, cart } = payload;
    try {
      for (let entry of addToCartItems) {
        window.TriplePixel('AddToCart', {
          item: entry.variantId,
          q: 1,
          token: cart.id,
        });
      }
    } catch (err) {
      console.log(err);
    }

    this.klaviyoService.addToCart(addToCartItems, cart);
    this.gtmService.addToCart(addToCartItems, cart);
    this.fbpService.addToCart(addToCartItems, cart);
    this.shopifyAnalyticsService.addToCart(cart);
  }

  viewCart(payload: { cart: Cart }) {
    const { cart } = payload;
    // this.klaviyoService.viewCart(cart);
    this.gtmService.viewCart(cart);
    this.shopifyAnalyticsService.viewPage(cart);

    // this.fbpService.viewCart(cart);
  }

  startCheckout(payload: { cart: Cart }) {
    const { cart } = payload;
    // this.klaviyoService.startCheckout(cart);
    // this.gtmService.startCheckout(cart);
    // this.fbpService.startCheckout(cart);
  }

  startNDISCheckout(payload: { cart: Cart }) {
    const { cart } = payload;
    this.gtmService.startNDISCheckout(cart);
    this.fbpService.startNDISCheckout(cart);
    this.klaviyoService.ndisCheckout(cart);
  }

  addToWishlist(payload: { variant: ProductVariant }) {
    const { variant } = payload;
    // this.klaviyoService.addToWishlist(variant);
    // this.gtmService.addToWishlist(variant);
    // this.fbpService.addToWishlist(variant);
  }

  search(payload: { query: string }) {
    const { query } = payload;
    // this.klaviyoService.search(query);
    // this.gtmService.search(query);
    this.fbpService.search(query);
    this.shopifyAnalyticsService.viewPage(query);
  }
}
