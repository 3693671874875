<div class="content" [appStoryblok]="_editable">
  <ng-container *ngIf="story.content">
    <app-ribbon
      class="z-3"
      *ngIf="_showribbon"
      [appStoryblok]="_editable"
      [theme]="story"
    ></app-ribbon>
    <app-header
      class="z-3"
      [showribbon]="_showribbon"
      [appStoryblok]="_editable"
      [theme]="story"
    ></app-header>
  </ng-container>
  <router-outlet *ngIf="!_notFound"></router-outlet>
  <router-outlet *ngIf="_notFound" name="error"></router-outlet>
</div>
<app-footer class="z-3" [appStoryblok]="_editable" [theme]="story"></app-footer>
@defer {
<app-minicart [theme]="story"></app-minicart>
} @placeholder {
<div></div>
}
